<template>
  <div>
    <panel-description
      :paymentMethodName="paymentMethodName"
      :virtualAccountNumber="virtualAccountNumber"
      :viewMybookingUrl="viewMybookingUrl"
      :price="price"
      :orderNumber="orderNumber"
    />
    <div class="container py-3">
      <countdown :paymentDueDiff="paymentDueDiff" class="mt-4"></countdown>
      <panel-steps
        :content="content"
        :viewMybookingUrl="viewMybookingUrl"
        v-if="content"
      ></panel-steps>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Countdown from '@/components/how-to-pay/partials/countdown';
import PanelDescription from '@/components/how-to-pay/partials/panel-description';
import PanelSteps from '@/components/how-to-pay/partials/panel-steps';
export default {
  name: 'bni',
  components: {
    PanelDescription,
    Countdown,
    PanelSteps,
  },
  props: [
    'paymentMethodName',
    'price',
    'virtualAccountNumber',
    'viewMybookingUrl',
    'orderNumber',
    'paymentDueDiff',
  ],
  data: () => ({
    content: null,
  }),
  computed: {
    ...mapState({
      bookingOrder: (state) => state.v2.booking.bookingOrder,
    }),
  },
  mounted() {
    this.content = {
      tabs: [
        {
          title: 'via ATM BRI / Bersama',
          steps: [
            this.$t('booking.guide.briBersama.msg1'),
            this.$t('booking.guide.briBersama.msg2'),
            this.$t('booking.guide.briBersama.msg3'),
            this.$t('booking.guide.briBersama.msg4'),
            this.$t('booking.guide.briBersama.msg5'),
            `${this.$t('booking.guide.briBersama.msg6')} ${this.virtualAccountNumber}`,
            this.$t('booking.guide.briBersama.msg7'),
            this.$t('booking.guide.briBersama.msg8'),
            this.$t('booking.guide.briBersama.msg9'),
          ],
        },
        {
          title: 'via Internet Banking BRI',
          steps: [
            this.$t('booking.guide.briInternet.msg1'),
            this.$t('booking.guide.briInternet.msg2'),
            this.$t('booking.guide.briInternet.msg3'),
            this.$t('booking.guide.briInternet.msg4'),
            `${this.$t('booking.guide.briInternet.msg5')} ${this.virtualAccountNumber}.`,
            this.$t('booking.guide.briInternet.msg6'),
            this.$t('booking.guide.briInternet.msg7'),
            this.$t('booking.guide.briInternet.msg8'),
            this.$t('booking.guide.briInternet.msg9'),
          ],
        },
        {
          title: 'via Mobile Banking BRI',
          steps: [
            this.$t('booking.guide.briMobile.msg1'),
            this.$t('booking.guide.briMobile.msg2'),
            this.$t('booking.guide.briMobile.msg3'),
            `${this.$t('booking.guide.briMobile.msg4')} ${this.virtualAccountNumber} ${this.$t(
              'booking.guide.briMobile.msg5',
            )}.`,
            this.$t('booking.guide.briMobile.msg6'),
            this.$t('booking.guide.briMobile.msg7'),
            this.$t('booking.guide.briMobile.msg8'),
          ],
        },
        {
          title: 'via Internet Banking Bank lain',
          steps: [
            this.$t('booking.guide.briOther.msg1'),
            this.$t('booking.guide.briOther.msg2'),
            `${this.$t('booking.guide.briOther.msg3')} ${this.virtualAccountNumber} ${this.$t(
              'booking.guide.briOther.msg4',
            )}`,
            this.$t('booking.guide.briOther.msg5'),
            this.$t('booking.guide.briOther.msg6'),
            this.$t('booking.guide.briOther.msg7'),
          ],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
